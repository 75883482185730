/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
 const API_HOST = "http://api.logikko.prod-projet.com/";
const API = {
  auth: {
    login: 'admin/login',
    signUp: 'signup',
  },
  aio: {
    aioActif : '',
    aioInactif : '',
  }
};

export { API_HOST, API };
