import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { projectReducer, SingleProjectReducer } from './project/reducers';
import { fsCrudReducer, fsSingleCrudReducer } from './firebase/firestore/reducers';
import firebaseAuth from './firebase/auth/reducers';

const rootReducers = combineReducers({
  fb: firebaseReducer,
  fs: firestoreReducer,
  auth: authReducer,
  projects: projectReducer,
  project: SingleProjectReducer,
  ChangeLayoutMode,
  crud: fsCrudReducer,
  singleCrud: fsSingleCrudReducer,
  firebaseAuth,
});

export default rootReducers;
