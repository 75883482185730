import { Col, Row } from 'antd';
import React from 'react';
import { Aside, Content } from './overview/style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row className='authRow'>
        <Col xxl={12} xl={9} lg={12} md={10} xs={24}>
          <Aside>
            <div className="auth-side-content">
              <Content>
                {/* needed to add style in balise, no other solution was found to force the style */}
                <span className='title'><span className='number'>5k+</span> clients staisfaits</span>
                <span className='lorem'>
                  " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras fringilla vehicula massa, non scelerisque ipsum pellentesque sit amet. Cras lobortis ultricies turpis. Ut at efficitur sapien. "
                </span>
                <span className='date'>abs 1981</span>
                <span className='user'>- Utilisateur ShARTrade</span>
              </Content>
            </div>
          </Aside>
        </Col>
        <Col xxl={8} xl={14} lg={12} md={14} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
