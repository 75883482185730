import React, { useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const { SubMenu } = Menu;

const MenuItems = ({ toggleCollapsed }) => {
  const { path } = useRouteMatch();
  var pathName = window.location.pathname.split('/');
  const [openKeys, setOpenKeys] = useState(pathName.length > 2 ? pathName.slice(2, pathName.length) : []);
  /* click on sub menu with item */
  const onOpenChange = keys => {
    setOpenKeys([keys.pop()]);
  };
  /* click on sub menu without items */
  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={'inline'}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      defaultSelectedKeys={[window.location.pathname.split('/').pop() === "admin" ? 'dashboard' : window.location.pathname.split('/').pop()]}
    >
      {/* Dashboard */} 
      <Menu.Item key="dashboard"
        icon={
          <NavLink className="menuItem-iocn" to={`${path}`}>
            <FeatherIcon icon="home" />
          </NavLink>
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      {/* AIO */}
      <SubMenu key="AIO" icon={<FeatherIcon icon="cpu" />} title="Gestion des AIO">
        {/* Actif AIO */}
        <Menu.Item key="actif">
          <NavLink onClick={toggleCollapsed} to={`${path}/AIO/actif`}>
            AIO Actifs
          </NavLink>
        </Menu.Item>
        {/* Inactif AIO */}
        <Menu.Item key="inactif">
          <NavLink onClick={toggleCollapsed} to={`${path}/AIO/inactif`}>
            Inventaires
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* Users */}
      <SubMenu key="users" icon={<FeatherIcon icon="users" />} title="Utilisateurs">
        {/* Clients */}
        <Menu.Item key="clients">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/clients`}>
            Clients
          </NavLink>
        </Menu.Item>
        {/* Monteurs */}
        <Menu.Item key="monteurs">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/monteurs`}>
            Monteurs
          </NavLink>
        </Menu.Item>
        {/* Monteurs */}
        <Menu.Item key="admins">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/admins`}>
            Admins
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* Data */}
      <SubMenu key="data" icon={<FeatherIcon icon="database" />} title="Données">
      </SubMenu>

      {/* Notices */}
      <SubMenu key="notices" icon={<FeatherIcon icon="file-text" />} title="Gestion des notices">
      </SubMenu>

      {/* Divers */}
      <SubMenu key="divers" icon={<FeatherIcon icon="activity" />} title="Divers">
      </SubMenu>

    </Menu>
  );
};

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
