import Styled from 'styled-components';

const Aside = Styled.aside`
  width: 130%;
  height: 100vh;
  position: relative;
  background:linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${require('../../../static/img/auth/authPic.png')}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  @media only screen and (max-width: 1199px){
    width: 100%
  }
  span{
    color:#fff;
  }
  @media (min-width: 425px) and (max-width: 767px){
    height: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 424px){
    height: 100%;
  }
  .auth-side-content{
    @media only screen and (max-width: 991px){
      h1{
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 767px){
      h1{
        font-size: 24px;
        margin-bottom: 28px;
      }
    }
  }
`;

const Content = Styled.div`
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: 100vh;
    .number{
      font-weight: 900;
    }
    .title{
      margin-bottom: 10px
    }
    .date{
      font-size: 12px;
      margin-bottom: 20px;
    }
    .user{
      font-weight: 600;
      font-size: 15px
    }
    .lorem{
      line-height: 50px
    }
    span{
      font-size: 20px;
      text-align:center;
    }
    @media only screen and (max-width: 1599px){
      padding: 50px;
    }
    @media only screen and (max-width: 991px){
      padding: 20px;
    }
    @media only screen and (max-width: 767px){
      text-align: center;
    }
    .auth-content-figure{
      @media only screen and (max-width: 1199px){
        max-width: 420px;
      }
      @media only screen and (max-width: 991px){
        max-width: 100%;
      }
    }
`;

const AuthWrapper = Styled.div`
  height: 100%;
  padding: 40px;
  @media only screen and (max-width: 1599px){
    padding: 25px;
  }

  @media only screen and (max-width: 767px){
    text-align: center;
  }
  .auth-notice{
    text-align: right;
    font-weight: 500;
    color: ${({ theme }) => theme['gray-color']};
    @media only screen and (max-width: 767px){
      text-align: center;
      margin-bottom: 10px;
    }
  }
  button{
    &.btn-signin{
      min-width: 185px;
      background-color: #000;
      width: 100%;
      color: #fff;
      font-sie: 14px!important;
      border-radius:0;
      border:none
    }
  }
  .auth-contents{
    display: flex;
    align-items: center;
    justify-content: center;
    form{
      max-width: 420px;
      
      @media only screen and (min-width: 426px){
        min-width: 420px;
      }
      h1{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 45px;
        @media only screen and (max-width: 767px){
          margin-bottom: 28px;
        }
        input::placeholder{
          color: ${({ theme }) => theme['extra-light-color']};
        }
      }
      .auth-form-action{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        span{
          font-size: 13px;
          color: #9299B8;
        }
        @media only screen and (max-width: 379px){
          flex-flow: column;
          .forgot-pass-link{
            margin-top: 15px;
          }
        }
      }
    }
  }
`;

export { Aside, Content, AuthWrapper };
