import Styled from 'styled-components';

const H1 = Styled.h1`
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
`;

const H2 = Styled.h2`
  font-size: 35px;
  font-weight: 900;
  line-height: 30px;
  color: #000;
  p{
    font-size:15px;
  }
  span{
    line-height: 55px
  }
`;

const H3 = Styled.h1`
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
`;

const H4 = Styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const H5 = Styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
`;

const H6 = Styled.h1`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export { H1, H2, H3, H4, H5, H6 };
