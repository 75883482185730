import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/dashboard'));
const AIOActif = lazy(() => import('../../container/AIO/AIO-actif/index'));
const AIOInactif = lazy(() => import('../../container/AIO/AIO-inactif/index'));
const AIODetail = lazy(() => import('../../container/AIO/AIO-detail/index'));
const Admins = lazy(() => import('../../container/users/admins/index'));
const Clients = lazy(() => import('../../container/users/clients/index'));
const Monteurs = lazy(() => import('../../container/users/monteurs/index'));

const Admin = () => {
  const { path } = useRouteMatch();

  const fallback = () => {
    return (
      <div className="spin">
        <Spin />
      </div>
    )
  }

  return (
    <Switch>
      <Suspense fallback={fallback()}>
        <Route exact path={path} component={Dashboard} />
        <Route exact path={`${path}/AIO/actif`} component={AIOActif} />
        <Route exact path={`${path}/AIO/inactif`} component={AIOInactif} />
        <Route path={`${path}/AIO/detail`} component={AIODetail} />
        <Route exact path={`${path}/users/clients`} component={Clients} />
        <Route exact path={`${path}/users/monteurs`} component={Monteurs} />
        <Route exact path={`${path}/users/admins`} component={Admins} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
