import thunk from "redux-thunk" 
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './rootReducers';


const middleware = [thunk];
const devtools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (f) => f;

const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middleware), devtools)
);


export default store;
